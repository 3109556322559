import "./Loader.css";

export const Loader = (className = "") => {
  return (
    <div className={`loader-page ${className}`}>
      <div className="loader-container">
        <i className="loader"></i>
      </div>
      <p className="loader-text">Ładowanie</p>
    </div>
  );
};
