import React, { useState, useRef, useEffect } from "react";
import './add-model.css';
import { postFile } from '../../shared/apiCaller/base-api-caller';
import { InputLabel, Button, TextField } from '@mui/material';
import '../../shared/styles/styles.css'
import { default as LoaderSpinner } from "../../shared/loader-spinner/loader-spinner";
const AddModelDialog = ({ isOpen, onClose, onConfirm }) => {
  const [file, setFile] = useState(null);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});
  const fileInputRef = useRef(null);

  useEffect(() => {
    setFile(null)
    setData({})
  }, [isOpen]);


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log("Selected file:", file);
    setFile(file);
  };

  const handleFileButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const confirm = async () => {
    if (!validateForm()) return;
    if (!file) {
      alert("Proszę wybrać plik tekstury!");
      return;
    }
    setLoading(true);
    const res = await postFile('/Models', file, data);
    if (res.isSuccess) {
      onConfirm();
    }
    setLoading(false);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value
    }));
    const fieldError = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: fieldError,
    }));
  };
  const validateField = (name, value) => {
    let error = '';
    if (name === 'name' && !value) error = 'Nazwa modelu jest wymagana';
    return error;
  };
  const validateForm = () => {
    let formErrors = {};
    if (!data.name) formErrors.name = "Nazwa modelu jest wymagana";
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };
  if (!isOpen) return null;

  if (loading)
    return (
      <div className="overlay">
        <div className="dialog">
          <div className="center">
            <div className="loader-spinner" style={{ fontSize: "240px" }}>
              <LoaderSpinner />
            </div>
          </div>
        </div>
      </div>
    )

  return (
    <div className="overlay">
      <div className="dialog">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-6">
            <InputLabel id="product-id-label">Nazwa modelu: *</InputLabel>
            <TextField
              variant="filled"
              fullWidth
              className="custom-input"
              name="name"
              value={data.name || ''}
              onChange={handleInputChange}
              error={Boolean(errors.name)}
              helperText={errors.name}
            />
          </div>
        </div>
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-6">
            <InputLabel htmlFor="file-upload">Wybierz plik modelu</InputLabel>
            <input
              type="file"
              id="file-upload"
              accept=".glb"
              onChange={handleFileChange}
              className="file-input"
              ref={fileInputRef}
              style={{ display: 'none' }} // Ukrycie elementu
            />
            <Button
              variant="outlined"
              className="save"
              onClick={handleFileButtonClick}
            >
              Wybierz plik
            </Button>
          </div>
        </div>
        <div className="row">
          <p className="file-name">Wybrano: {file?.name}</p>
        </div>
        <div className="row confirm-buttons">
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <Button variant="contained" className='cancel' onClick={onClose}>
              Anuluj
            </Button>
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <Button variant="contained" className='save' onClick={confirm}>
              Zapisz
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddModelDialog;
