import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  TextField,
  Select,
  InputLabel,
  Button,
  MenuItem,
  FormHelperText,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import { IoChevronBack } from "react-icons/io5";
import { MdOutlineAddBox } from "react-icons/md";
import {
  getById,
  getSelectable,
  get,
  post,
  put,
  postFile,
} from "../../shared/apiCaller/base-api-caller";
import AddTextureDialog from "../../pages/textures/add-textures";
import AddModelDialog from "../../pages/models/add-model";
import Viewer from "../configurator/viewer/ModelView";
import "./add-products.css";
import { PiSupersetOfBold } from "react-icons/pi";

const AddProduct = () => {
  const [isTextureDialogOpen, setTextureDialogOpen] = useState(false);
  const [isModelDialogOpen, setModelDialogOpen] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [isRotationEnable, setRotationEnable] = useState(false);
  const [data, setData] = useState({});
  const [models, setModels] = useState([]);
  const [textures, setTextures] = useState([]);
  const [texturesToAdd, setTexturesToAdd] = useState([]);
  const [base64TexturesToAdd, setBase64TexturesToAdd] = useState([]);
  const [selectedTexture, setSelectedTexture] = useState({});
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    if (id) {
      GetProduct(id);
    }

    if (models.length === 0) {
      GetModels();
    }
    if (textures.length === 0 && id) {
      GetTextures(id);
    }
  }, [location, models.length, textures.length]);

  const GetProduct = async (id) => {
    const res = await getById("/Products", id);
    setData(res.content);
    setRotationEnable(res.content.isRotationEnable)
  };

  const GetModels = async () => {
    const res = await getSelectable("/Models");
    setModels(res.content);
  };

  const GetTextures = async (id) => {
    const res = await get(`/Textures/all/${id}`);
    setTextures(res.content);
  };
  const changeBool= () => {
    setData((prevData) => ({
      ...prevData,
      isRotationEnable: !data.isRotationEnable,
    }));
    
    setRotationEnable(!isRotationEnable)
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name);
    console.log(value);

    const sanitizedValue = [
      "height",
      "width",
      "depth",
      "maxHorizontalLevels",
      "maxVerticalLevels",
    ].includes(name)
      ? value.replace(/[^0-9]/g, "")
      : value;

    setData((prevData) => ({
      ...prevData,
      [name]: sanitizedValue,
    }));

    const fieldError = validateField(name, sanitizedValue);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: fieldError,
    }));
  };
  const validateField = (name, value) => {
    let error = "";
    if (name === "mainId" && !value) error = "ID Produktu jest wymagane";
    if (name === "name" && !value) error = "Nazwa produktu jest wymagana";
    if (name === "modelId" && !value) error = "Wariant jest wymagany";

    if (name === 'height' && !value) error = 'Wysokość jest wymagana';
    if (name === 'width' && !value) error = 'Szerokość jest wymagana';
    if (name === 'depth' && !value) error = 'Głębokość jest wymagana';
    if (name === 'height' && value <= 0) error = "Wysokość musi być dodatnia";
    if (name === 'width' && value <= 0) error = "Szerokość musi być dodatnia";
    if (name === 'depth' && value <= 0) error = "Głębokość musi być dodatnia";

    if (name === 'maxHorizontalLevels' && !value) error = 'L. poziomów (max) jest wymagana';
    if (name === 'maxVerticalLevels' && !value) error = 'L. pionów (max) jest wymagana';
    if (name === 'maxHorizontalLevels' && value <= 0) error = "L. poziomów (max) musi być dodatnia";
    if (name === 'maxVerticalLevels' && value <= 0) error = "L. pionów (max) musi być dodatnia";

    return error;
  };
  const validateForm = () => {
    let formErrors = {};
    if (!data.mainId) formErrors.mainId = "ID Produktu jest wymagane";
    if (!data.name) formErrors.name = "Nazwa produktu jest wymagana";
    if (!data.modelId) formErrors.modelId = "Wariant jest wymagany";

    if (!data.height) formErrors.height = "Wysokość jest wymagana";
    if (!data.width) formErrors.width = "Szerokość jest wymagana";
    if (!data.depth) formErrors.depth = "Głębokość jest wymagana";
    if (data.height <= 0) formErrors.height = "Wysokość musi być dodatnia";
    if (data.width <= 0) formErrors.width = "Szerokość musi być dodatnia";
    if (data.depth <= 0) formErrors.depth = "Głębokość musi być dodatnia";

    if (!data.maxHorizontalLevels) formErrors.maxHorizontalLevels = "L. poziomów (max) jest wymagana";
    if (!data.maxVerticalLevels) formErrors.maxVerticalLevels = "L. pionów (max) jest wymagana";
    if (data.maxHorizontalLevels <= 0) formErrors.maxHorizontalLevels = "L. poziomów (max) musi być dodatnia";
    if (data.maxVerticalLevels <= 0) formErrors.maxVerticalLevels = "L. pionów (max) musi być dodatnia";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const submit = async () => {
    if (!validateForm()) return; // Zatrzymaj wysyłanie formularza, jeśli są błędy
    if (isSaving)
      return;
    console.log(data)
    setSaving(true);
    let res;
    if (data.id) {
      res = await put("/Products", data);
    } else {
      res = await post("/Products", data);
    }
    if (res.isSuccess) {
      if (texturesToAdd)
        texturesToAdd.forEach(async (element) => {
          const fileRes = await postFile("/Textures", element, {
            id: data.id ?? res.content.id,
          });
          if (!fileRes.isSuccess) {
            alert("Błąd dodania tekstury!");
          }
        });
    }
    if (res.isSuccess) back();
    else {
      setSaving(false);
      if (res.error = "Product already exists") {
        let formErrors = {};
        formErrors.mainId = "ID Produktu już istnieje";
        setErrors(formErrors);
      }
    }
  };

  const back = () => {
    navigate(`/products`);
  };

  const handleImageClick = (id) => {
    setSelectedTexture({
      id: id,
      index: null,
    });
  };
  const handleToAddImageClick = (index) => {
    setSelectedTexture({
      id: null,
      index: index,
    });
  };

  const handleTextureAddDialog = () => {
    setTextureDialogOpen(true);
  };
  const closeTextureDialog = () => {
    setTextureDialogOpen(false);
  };
  // const confirmAddTexture = async (file) => {
  //   setTextureDialogOpen(false);
  //   setTexturesToAdd((prevData) => [...prevData, file]);

  //   try {
  //     const base64Result = await convertFileToBase64(file);
  //     setBase64TexturesToAdd((prevData) => [...prevData, base64Result]);
  //   } catch (error) {
  //     console.error("Error converting file to Base64:", error);
  //   }
  // };
  const confirmAddTexture = async (file) => {
    setTextureDialogOpen(false);
    setTexturesToAdd((prevData) => [...prevData, file]);

    try {
      const base64Result = await convertFileToBase64(file);
      setBase64TexturesToAdd((prevData) => {
        const newArray = [...prevData, base64Result];
        // Ustaw ostatnio dodaną teksturę jako wybraną
        setSelectedTexture({
          id: null,
          index: newArray.length - 1,
        });
        return newArray;
      });
    } catch (error) {
      console.error("Error converting file to Base64:", error);
    }
  };
  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve({ id: file.name, base64: reader.result });
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const handleModelAddDialog = () => {
    setModelDialogOpen(true);
  };
  const closeModelDialog = () => {
    setModelDialogOpen(false);
  };
  const confirmAddModel = () => {
    setModelDialogOpen(false);
    GetModels();
  };

  return (
    <div className="container">
      <div className="row main">
        <div className="col-md-8">
          <div className="row">
            <div className="col-md-6 title">
              <IoChevronBack
                style={{ transform: "scale(1.3)", cursor: "pointer" }}
                onClick={back}
              />
              <div className="title-name">
                {data.id != null ? "EDYCJA PRODUKTU" : "DODANIE PRODUKTU"}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <InputLabel id="product-id-label">ID Produktu: *</InputLabel>
              <TextField
                variant="filled"
                fullWidth
                className="custom-input"
                name="mainId"
                value={data.mainId || ""}
                onChange={handleInputChange}
                error={Boolean(errors.mainId)}
                helperText={errors.mainId}
              />
            </div>
            <div className="col-md-6">
              <InputLabel id="product-name-label">Nazwa produktu: *</InputLabel>
              <TextField
                variant="filled"
                fullWidth
                className="custom-input"
                name="name"
                value={data.name || ""}
                onChange={handleInputChange}
                error={Boolean(errors.name)}
                helperText={errors.name}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <InputLabel id="variant-label">Wybierz wariant: *</InputLabel>
              <div className="row">
                <div className="col-md-6">
                  <Select
                    labelId="variant-label"
                    variant="filled"
                    fullWidth
                    className="custom-input"
                    name="modelId"
                    value={data.modelId || ""}
                    onChange={handleInputChange}
                    error={Boolean(errors.modelId)}
                    helperText={errors.modelId}
                  >
                    {models.map((model) => (
                      <MenuItem key={model.id} value={model.id}>
                        {model.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div
                  className="col-md-1 px-1"
                  onClick={() => handleModelAddDialog("model")}
                  title="Dodaj model"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <MdOutlineAddBox />
                </div>
                <div
                  className="col-md-5"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isRotationEnable"
                        checked={isRotationEnable}
                        onClick={() => changeBool()}
                      />
                    }
                    label="Obrót 180 stopni / lustrzane odbicie"
                  />
                </div>
                <div className="col-md-6">
                  <FormHelperText
                    className="MuiFormHelperText-root 
                      Mui-error MuiFormHelperText-sizeMedium 
                      MuiFormHelperText-contained css-er619e-MuiFormHelperText-root
                      selectError"
                  >
                    {errors.modelId}
                  </FormHelperText>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <InputLabel id="height-label">Wysokość (cm): *</InputLabel>
              <TextField
                variant="filled"
                fullWidth
                className="custom-input"
                name="height"
                value={data.height || ""}
                onChange={handleInputChange}
                error={Boolean(errors.height)}
                helperText={errors.height}
              />
            </div>
            <div className="col-md-3">
              <InputLabel id="width-label">Szerokość (cm): *</InputLabel>
              <TextField
                variant="filled"
                fullWidth
                className="custom-input"
                name="width"
                value={data.width || ""}
                onChange={handleInputChange}
                error={Boolean(errors.width)}
                helperText={errors.width}
              />
            </div>
            <div className="col-md-3">
              <InputLabel id="depth-label">Głębokość (cm): *</InputLabel>
              <TextField
                variant="filled"
                fullWidth
                className="custom-input"
                name="depth"
                value={data.depth || ""}
                onChange={handleInputChange}
                error={Boolean(errors.depth)}
                helperText={errors.depth}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <InputLabel id="textures-label">Dostępne tekstury:</InputLabel>
              <div className="col-md-12 textures-container">
                {textures.map((texture, index) => (
                  <div
                    key={index}
                    className="block"
                    style={{
                      border:
                        selectedTexture.id === texture.id
                          ? "solid 5px gold"
                          : "none",
                      padding: selectedTexture.id === texture.id ? "0" : "5px",
                    }}
                    onClick={() => handleImageClick(texture.id)}
                  >
                    <img
                      src={`data:image/jpeg;base64,${texture.base64Image}`}
                      alt={`Texture ${index}`}
                    />
                  </div>
                ))}
                {base64TexturesToAdd.map((texture, index) => (
                  <div
                    key={index}
                    className="block"
                    style={{
                      border:
                        selectedTexture.index === index
                          ? "solid 5px gold"
                          : "none",
                      padding: selectedTexture.index === index ? "0" : "5px",
                    }}
                    onClick={() => handleToAddImageClick(index)}
                  >
                    <img src={texture.base64} alt={`Texture ${index}`} />
                  </div>
                ))}
                <div
                  onClick={() => handleTextureAddDialog("texture")}
                  title="Dodaj teksturę"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <MdOutlineAddBox />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <InputLabel id="levels-label">L. poziomów (max): *</InputLabel>
              <TextField
                variant="filled"
                fullWidth
                className="custom-input"
                name="maxHorizontalLevels"
                value={data.maxHorizontalLevels || ""}
                onChange={handleInputChange}
                error={Boolean(errors.maxHorizontalLevels)}
                helperText={errors.maxHorizontalLevels}
              />
            </div>
            <div className="col-md-3">
              <InputLabel id="columns-label">L. pionów (max): *</InputLabel>
              <TextField
                variant="filled"
                fullWidth
                className="custom-input"
                name="maxVerticalLevels"
                value={data.maxVerticalLevels || ""}
                onChange={handleInputChange}
                error={Boolean(errors.maxVerticalLevels)}
                helperText={errors.maxVerticalLevels}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4" style={{ paddingRight: "0" }}>
          <div className="container3d">
            <Viewer
              modelId={data.modelId}
              selectedTexture={selectedTexture}
              textures={textures}
              base64TexturesToAdd={base64TexturesToAdd}
            />
          </div>
        </div>
      </div>
      <div
        className="row buttons justify-content-end align-items-center"
        style={{ marginBottom: "20px !important" }}
      >
        <div className="col-md-2 d-flex justify-content-end align-items-center">
          <Button
            variant="contained"
            className="cancel noBorder"
            onClick={back}
          >
            Anuluj
          </Button>
        </div>
        <div className="col-md-2 d-flex justify-content-end align-items-center">
          <Button variant="contained" className="save" onClick={submit}>
            Zapisz
          </Button>
        </div>
      </div>
      <AddTextureDialog
        isOpen={isTextureDialogOpen}
        onClose={closeTextureDialog}
        onConfirm={confirmAddTexture}
      />
      <AddModelDialog
        isOpen={isModelDialogOpen}
        onClose={closeModelDialog}
        onConfirm={confirmAddModel}
      />
    </div>
  );
};

export default AddProduct;
