import React, { useState } from 'react';
import { MdOutlineSearch, MdClear } from "react-icons/md";
import { TextField } from '@mui/material';

const SearchInput = ({ setCurrentFilters, currentFilters }) => {
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [isTextFieldFocused, setTextFieldFocused] = useState(false);

  const handleSearchChange = (event) => {
    setCurrentFilters((prevFilter) => ({ ...prevFilter, searchTerm: event.target.value, pageNumber: 1  }));
  };

  const handleSearch = (hide) => {
    setSearchOpen(hide);
    setCurrentFilters((prevFilter) => ({ ...prevFilter, searchTerm: ""}));

    // Ustaw TextField jako kliknięty
    if (hide) {
      setTextFieldFocused(true);
    } else {
      setTextFieldFocused(false);
    }
  };

  if (!isSearchOpen) return (
    <div className="col-md-1 d-flex justify-content-end align-items-center"
      onClick={() => handleSearch(true)}>
        <div className="search-icon-container d-flex justify-content-end align-items-center">
          <MdOutlineSearch
            title="Wyszukaj"
            className='search-icon '
          />
        </div>

    </div>
  );

  return (
    <div className="col-md-3 search-term">
      <div className="d-flex flex-grow-1 search-term-inside">
        <div className="d-flex justify-content-center align-items-center left">
          <MdOutlineSearch className='search-icon left' onClick={() => handleSearch(true)} />
        </div>
        <div className="flex-grow-1 left" >
          <TextField
            label="Szukaj"
            className="search-input roboto-thin"
            value={currentFilters.searchTerm || ''}
            onChange={handleSearchChange}
            InputLabelProps={{
              className: 'search-label'
            }}
            sx={{
              "& fieldset": { border: 'none' },
            }}
            focused={isTextFieldFocused} // Ustawia TextField jako aktywowany
          />
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <MdClear title="Ukryj" className='search-icon left' onClick={() => handleSearch(false)} />
        </div>
      </div>
    </div>
  );
};

export default SearchInput;
