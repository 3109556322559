import React, { useState, useRef, useEffect } from "react";
import './add-textures.css';
import { InputLabel, Button } from '@mui/material';

const AddTextureDialog = ({ isOpen, onClose, onConfirm }) => {
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  useEffect(() => {
    setFile(null)
  }, [isOpen]);
  
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
  };

  const handleFileButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Symulacja kliknięcia w ukryty input
    }
  };

  const confirm = async () => {
    if (!file) {
      alert("Proszę wybrać plik tekstury!");
      return;
    }
    await onConfirm(file);
  };

  if (!isOpen) return null;

  return (
    <div className="overlay">
      <div className="dialog">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-6">
            <InputLabel htmlFor="file-upload">Wybierz plik tekstury</InputLabel>
            <input
              type="file"
              id="file-upload"
              accept="image/png, image/jpeg"
              onChange={handleFileChange}
              className="file-input"
              ref={fileInputRef}
              style={{ display: 'none' }} // Ukrycie elementu
            />
            <Button
              variant="outlined"
              className="save"
              onClick={handleFileButtonClick}
            >
              Wybierz plik
            </Button>
          </div>
        </div>
        <div className="row">
          <p className="file-name">Wybrano: {file?.name}</p>
        </div>
        <div className="row confirm-buttons">
          <div className="col-md-6 d-flex justify-content-center align-items-end">
            <Button variant="contained" className='cancel' onClick={onClose}>
              Anuluj
            </Button>
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-end">
            <Button variant="outlined" className='save' onClick={confirm}>
              Zapisz
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTextureDialog;
