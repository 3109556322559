import React from "react";
import { Button } from '@mui/material';

const DeleteDialog = ({ isOpen, onClose, onConfirm, element }) => {
  if (!isOpen) return null;

  return (
    <div className="overlay">
      <div className="dialog">
        <div className="row title flex justify-content-center align-items-center">
          Czy na pewno chcesz usunąć produkt {element.mainId}?
        </div>
        <div className="row confirm-buttons">
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <Button variant="contained" className='cancel' onClick={onClose}>
              Nie
            </Button>
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <Button variant="contained" className='save' onClick={onConfirm}>
              Tak, usuń!
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteDialog;
