import React, { Suspense, useRef } from "react";
import * as THREE from "three";
import { Canvas, useLoader } from "@react-three/fiber";
import { OrbitControls, useGLTF, Html, Stage } from "@react-three/drei";
import "./ModelView.css";
import environment from "../../../enviroments/enviroments";

function ModelCube({ textureUrl, modelUrl, position }) {
  const modelRef = useRef();

  const model = useGLTF(modelUrl);

  const texture = useLoader(THREE.TextureLoader, textureUrl);

  const clonedModel = model.scene.clone();

  clonedModel.traverse((child) => {
    if (child.isMesh) {
      child.castShadow = true;
      child.receiveShadow = true;
      child.material = new THREE.MeshStandardMaterial({
        map: texture,
      });
    }
  });

  const calculateScale = () => {
    const box = new THREE.Box3().setFromObject(model.scene);
    const size = new THREE.Vector3();
    box.getSize(size);

    if (size.x > 70) return [0.05, 0.05, 0.05];
    else return [0.7, 0.7, 0.7];
  };
  return (
    <group position={position} ref={modelRef}>
      <primitive object={clonedModel} scale={calculateScale()} />
    </group>
  );
}

export default function Viewer({
  modelId,
  selectedTexture,
  textures,
  base64TexturesToAdd,
}) {
  if (!modelId) {
    return (
      <div className="model-view-info">
        Aby wyświetlić wizualizację wybierz wariant lub dodaj nowy model
      </div>
    );
  }

  let textureUrl = null;

  if (selectedTexture.id == null && selectedTexture.index == null) {
    if (base64TexturesToAdd && base64TexturesToAdd.length > 0) {
      textureUrl = base64TexturesToAdd[base64TexturesToAdd.length - 1].base64;
    } else if (textures && textures.length > 0) {
      textureUrl = `data:image/jpeg;base64,${
        textures[textures.length - 1].base64Image
      }`;
    }
  } else {
    if (selectedTexture.id != null) {
      textureUrl = `${environment.apiRoot}/api/Textures/file/${selectedTexture.id}`;
    } else if (
      selectedTexture.index != null &&
      base64TexturesToAdd[selectedTexture.index]
    ) {
      textureUrl = base64TexturesToAdd[selectedTexture.index].base64;
    }
  }

  if (!textureUrl) {
    return (
      <div className="model-view-info">
        Aby wyświetlić wizualizację dodaj tekstury
      </div>
    );
  }

  const modelUrl = `${environment.apiRoot}/api/Models/file/${modelId}`;

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Canvas
        dpr={[1, 2]}
        shadows
        camera={{ fov: 50, position: [0, 0, 10] }}
        gl={{
          antialias: true,
          physicallyCorrectLights: true,
        }}
      >
        <Suspense
          fallback={
            <Html>
              <div
                className="model-view-info"
                style={{ transform: "translateX(-30px)" }}
              >
                Ładowanie
              </div>
            </Html>
          }
        >
          <Stage
            intensity={0.1}
            preset="rembrandt"
            shadows={{
              type: "accumulative",
              bias: -0.001,
              intensity: Math.PI + 1,
            }}
            adjustCamera={false}
          >
            <ModelCube
              modelUrl={modelUrl}
              textureUrl={textureUrl}
              position={[0, 0, 0]}
            />
          </Stage>
          <OrbitControls
            minPolarAngle={0}
            maxPolarAngle={Math.PI / 1.9}
            makeDefault
          />
        </Suspense>
      </Canvas>
    </div>
  );
}
