import React, { useState, useEffect } from 'react';
import { getPageable, deleteById } from '../../shared/apiCaller/base-api-caller';
import { default as LoaderSpinner } from "../../shared/loader-spinner/loader-spinner";
import { Pagination } from "@mui/material";
import './product-catalog.css';
import { MdOutlineModeEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import DeleteDialog from "../../shared/widgets/delete-dialog";
import SearchInput from "../../shared/widgets/search-input";
const ProductCatalog = ({
  heightOffset = 550,
  filters = { searchTerm: null, pageNumber: 1, pageSize: 25, desc: false, orderBy: null },
  isWithPaginator = true,
  maxHeight = 550
}) => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [height, setHeight] = useState(500);
  const [total, setTotal] = useState(0);
  const [currentFilters, setCurrentFilters] = useState(filters);
  const [loading, setLoading] = useState(true);
  const [tableData, setData] = useState([]);
  const [columnNames, setColumnNames] = useState(['ID Produktu', 'Nazwa Produktu', 'Wariant', 'Akcje']);
  const navigate = useNavigate(); // Get the navigate function


  useEffect(() => {
    onReload();
  }, [currentFilters]); // Trigger onReload whenever currentFilters changes

  const onReload = async () => {
    try {
      const data = await getPageable('/Products', currentFilters);
      setData(data.content.result);
      setTotal(data.content.total);
    } finally {
      setLoading(false);
    }
  };

  // Obliczanie liczby stron
  const totalPages = currentFilters
    ? Math.ceil(total / currentFilters.pageSize)
    : 0;

  // Ustawianie wysokości na podstawie okna
  useEffect(() => {
    const updateHeight = () => {
      const newHeight = window.innerHeight - heightOffset;
      setHeight(newHeight >= maxHeight ? maxHeight : newHeight);
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, [heightOffset, maxHeight]);

  const handlePageChange = (event, newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentFilters({ ...currentFilters, pageNumber: newPage });
    }
  };

  const handlePageSizeChange = (event) => {
    setCurrentFilters((prevFilter) => ({ ...prevFilter, pageSize: event.target.value }));
  };



  const addProduct = () => {
    navigate(`/products/edit`);
  };
  const editProduct = (id) => {
    navigate(`/products/edit?id=${id}`);
  };
  const deleteProduct = (element) => {
    setSelectedProduct(element);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setSelectedProduct(null);
  };

  const confirmDelete = async () => {
    console.log(selectedProduct.id)
    await deleteById('/Products', selectedProduct.id);
    onReload();
    closeDialog();
  };
  return (
    <div>
      <div className="row d-flex justify-content-end align-items-center">
        <SearchInput
          setCurrentFilters={setCurrentFilters}
          currentFilters={currentFilters}
        />
        <div className="col-md-2">
          <button className="add-product-btn roboto-thin w-100"  onClick={addProduct}>
            Dodaj produkt
          </button>
        </div>
      </div>
      <div className="table-container mat-elevation-z8">
        <div style={{ maxHeight: `${maxHeight}px`, overflowY: 'auto'  }}>
          {!loading ? (
            <div className="table-container-inside">
              <table className="table-content">
                <thead style={{ width: '100%'}}>
                  <tr>
                    {columnNames.map((column, index) => (
                      <th key={index}>
                        {column}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td>{row.mainId}</td>
                      <td>{row.name}</td>
                      <td>{row.model?.name}</td>
                      <td style={{ width: '10px' }}>
                        <MdOutlineModeEdit
                          style={{ marginRight: '5%' }}
                          title="Edytuj"
                          onClick={() => editProduct(row.id)}
                          className="acton-icon"
                        />
                        <RiDeleteBin6Line
                          style={{ marginRight: '5%' }}
                          title="Usuń"
                          onClick={() => deleteProduct(row)}
                          className="acton-icon"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="center">
              <div className="loader-spinner" style={{ fontSize: "240px" }}>
                <LoaderSpinner />
              </div>
            </div>
          )}
        </div>
        <div className="display-flex">
          <div className="flex-spacer"></div>
          {isWithPaginator && (
            <div className="pagination">
              <div className="pagination-info left">
                <span className='left'>Wyników na stronę</span>
                <span>
                  <select value={currentFilters.pageSize} onChange={handlePageSizeChange}>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="100">100</option>
                  </select>
                </span>
              </div>
              <div className="pagination-info">
                <span className='left'>Strona {currentFilters.pageNumber} z {totalPages} | Suma: {total}</span>
              </div>
              <Pagination
                count={totalPages}
                page={currentFilters.pageNumber}
                onChange={handlePageChange}
                showFirstButton
                showLastButton

              />
            </div>
          )}
        </div>
      </div>
      <DeleteDialog
        isOpen={isDialogOpen}
        onClose={closeDialog}
        onConfirm={confirmDelete}
        element={selectedProduct}
      />
    </div>
  );
};

export default ProductCatalog;
