import { useEffect, useState, Suspense } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { generateUUID } from "three/src/math/MathUtils.js";

import { Loader } from "./loader/Loader";
import Configurator from "./Configurator";
import environment from "../../enviroments/enviroments";
import "./ConfiguratorPage.css";

// DO USUNIĘCIA
// const mockItem = [
//   [0, 0, 0],
//   [0, 37.85538291931152, 0],
//   [99.94333248963812, 0, 0],
// ];

export default function ConfiguratorPage() {
  const { mainId } = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const show = searchParams.get("show");

  const [product, setProduct] = useState(null);

  const [selectedTexture, setCubeTexture] = useState();
  const [modelUrl, setModelUrl] = useState();
  const [textures, setTextures] = useState([]);
  const [items, setItems] = useState([]);

  const layoutURL = `${environment.apiRoot}/api/Layout`;

  const getProduct = async () => {
    const productUrl = `${environment.apiRoot}/api/Products/main/${mainId}`;
    const response = await fetch(productUrl, { method: "GET" });
    const productResponse = await response.json();
    if (productResponse.content) {
      setProduct(productResponse.content);
      const modelPath = `${environment.apiRoot}/api/Models/file/${productResponse.content.modelId}`;
      setModelUrl(modelPath);
      let layoutResponse;

      if (show && id) {
        // TO ODKOMENTUJ
        const layoutConfirmURL = `${layoutURL}/confirm/${id}`;
        const response = await fetch(`${layoutURL}/${id}`, { method: "GET" });
        await fetch(layoutConfirmURL, { method: "GET"});
        layoutResponse = await response.json();
        const itemsObject = layoutResponse.content.layout.map((position) => ({
          id: generateUUID(),
          position,
        }));

        // TO itemsObject USUŃ  - NIE USUWAJ setItems
        // const itemsObject = mockItem.map((position) => ({
        //   id: generateUUID(),
        //   position,
        // }));
        //

        setItems(itemsObject);
      }

      if (productResponse.content.textureIds) {
        const textureUrls = productResponse.content.textureIds.map(
          (textureId) => ({
            path: `${environment.apiRoot}/api/Textures/file/${textureId}`,
            id: textureId,
          })
        );
        // TO layoutResponse tutaj USUŃ to jest zmockowane normalnie będzie to pobierać
        // layoutResponse = { content: { textureId: textureUrls[1].id } };
        //
        setTextures(textureUrls);
        setCubeTexture(textureUrls[0]);
        if (show && id) {
          const matchedTexture = textureUrls.find(
            (texture) => texture.id === layoutResponse.content.textureId
          );
          if (matchedTexture) {
            setCubeTexture({
                path: matchedTexture.path,
                id: matchedTexture.id,
            });
        }
        }
      }
    } else {
      setProduct(null);
    }
  };

  const sendLayout = async (postion) => {
    const payload = { id: id, layout: postion, textureId: selectedTexture?.id };
    console.log(JSON.stringify(payload))
    await fetch(layoutURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
  };

  useEffect(() => {
    getProduct();
  }, []);

  return product ? (
    <>
      <Suspense fallback={<Loader />}>
        <div className="texture-container">
          {textures.length > 0 && (
            <div className="texture-text" style={{ fontSize: "15px" }}>
              Dostępne tekstury:
            </div>
          )}
          <div>
            {textures.map(({ path, id }, index) => (
              <button
                key={index}
                onClick={() => setCubeTexture({ path, id })}
                className={`texture-button ${
                  selectedTexture?.path === path ? "texture-selected" : ""
                }`}
                style={{ backgroundImage: `url(${path})` }}
              ></button>
            ))}
          </div>
          <div className="texture-text">
            {`Wysokość: ${product.height}cm, Szerokość: ${product.width}cm, Głębokość: ${product.depth}cm `}
          </div>
        </div>
        <Configurator
          modelUrl={modelUrl}
          maxHorizontalLevels={product.maxHorizontalLevels}
          maxVerticalLevels={product.maxVerticalLevels}
          texturePath={selectedTexture?.path}
          layoutItems={items}
          sendLayout={sendLayout}
          show={show}
          rotateEvenLayers={product.isRotationEnable}
        />
      </Suspense>
    </>
  ) : (
    <></>
  );
}
