const environment = {
  production: "true",
  // apiRoot: "https://localhost:44325",
  apiRoot: "https://ilusi-api.devitmcode.pl",
  apiDateFormat: "YYYY-MM-DD",
  identityRoot: "https://kaj-identity.devitmcode.pl",
  identityConfig: {
    //do zmian
    stsServer: "https://kaj-identity.devitmcode.pl",
    clientId: "kaj-web",
    scope: "openid profile offline_access IdentityServerApi kaj",
  },
};
export default environment;
