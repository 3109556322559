import React from 'react';
import PropTypes from 'prop-types';
import './loader-spinner.css';

const LoaderSpinner = ({ diameter = 200, inTable = true }) => {
  const spinnerStyle = {
    width: `${diameter}px`,
    height: `${diameter}px`,
    border: '4px solid rgba(0, 0, 0, 0.1)',
    borderTop: '4px solid #3f51b5', // accent color
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
  };

  return (
      <div style={spinnerStyle}></div>
  );
};

LoaderSpinner.propTypes = {
  diameter: PropTypes.number,
  inTable: PropTypes.bool,
};

export default LoaderSpinner;
