import environment from "../../enviroments/enviroments";

// src/apiCaller.js
const API_ROOT = environment.apiRoot+'/api'; // Zmień na odpowiedni URL
const IDENTITY_ROOT = environment.identityRoot; // Zmień na odpowiedni URL

// Helper function to handle HTTP requests
const apiCaller = async (path, options = {}) => {
  try {
    const token = localStorage.getItem('authToken');
    
    options.headers = {
      ...(options.headers || {})
    };

    if (token) {
      options.headers.Authorization = `Bearer ${token}`;
    }

    const response = await fetch(path, options);
    let data;
    try {
        data = await response.json();
    } catch (error) {
        console.error("Failed to parse JSON:", error);
        data = { isSuccess: false, result: null};
    }
    return data.result || data;
  } catch (error) {
    console.error('API call failed:', error);
    throw error;
  }
};

// Helper functions for different HTTP methods

const get = async (path, options = {}) => {
  const url = `${API_ROOT}${path}`;
  return await apiCaller(url, { method: 'GET', ...options });
};

const getPageable = async (path, options = {}) => {
  const url = new URL(`${API_ROOT}${path}/pageable`);
  Object.keys(options).forEach(key => {
    if (options[key] === null) {
      delete options[key];
    }
  });
  const params = new URLSearchParams(options);
  url.search = params.toString();

  return await apiCaller(url.toString(), { method: 'GET' });
};
const getById = async (path, id, options = {}) => {
  const url = `${API_ROOT}${path}/${id}`;
  return await apiCaller(url, { method: 'GET', ...options });
};

const getSelectable = async (path, id, options = {}) => {
  const url = `${API_ROOT}${path}/selectable`;
  return await apiCaller(url, { method: 'GET', ...options });
};

const post = async (path, body, options = {}) => {
  const url = `${API_ROOT}${path}`;
  const headers = { 'Content-Type': 'application/json', ...options.headers };
  const bodyData = JSON.stringify(body);
  return await apiCaller(url, { method: 'POST', headers, body: bodyData, ...options });
};
const postFile = async (path, file, options = {}) => {
  const url = `${API_ROOT}${path}`;
  
  const formData = new FormData();
  formData.append('file', file);
  if (options.name) {
    formData.append('name', options.name); 
  }
  console.log(options)
  if (options.id) {
    formData.append('productId', options.id); 
  }
  return await apiCaller(url, {
    method: 'POST',
    headers: {
      ...options.headers,
    },
    body: formData,
    ...options,
  });
};

const put = async (path, body, options = {}) => {
  const url = `${API_ROOT}${path}`;
  const headers = { 'Content-Type': 'application/json', ...options.headers };
  const bodyData = JSON.stringify(body);
  return await apiCaller(url, { method: 'PUT', headers, body: bodyData, ...options });
};

const deleteById = async (path, id, body = {}, options = {}) => {
  const url = `${API_ROOT}${path}/${id}`;
  const headers = { 'Content-Type': 'application/json', ...options.headers };
  const bodyData = JSON.stringify(body);
  return await apiCaller(url.toString(), { method: 'DELETE', headers, body: bodyData, ...options });
};
// Helper for building query params
const buildQueryParams = (params) => {
  const query = new URLSearchParams();
  Object.keys(params).forEach(key => {
    if (params[key] != null) {
      query.append(key, params[key]);
    }
  });
  return query.toString();
};

const getFullPath = (path) => {
  return `${API_ROOT}${path}`;
};

const getIdentityFullPath = (path) => {
  return `${IDENTITY_ROOT}${path}`;
};

export { get, getById, getPageable, getSelectable, post, postFile, put, deleteById, buildQueryParams, getFullPath, getIdentityFullPath };
