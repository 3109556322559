import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';
import './navbar.css'; // Plik z CSS dla nawigacji

const Navbar = () => {
  return (
    <div className="container">
      <div className='row'>
        <div className='col-md-12 d-flex'>
          <div className='col-md-2 navbar-logo d-flex align-items-center'>
            <a href="https://ilusi.eu/" target="_blank" rel="noopener noreferrer">
              <img src={logo} alt="Company logo" />
            </a>
          </div>
          <div className='col-md-3 d-flex align-items-center'>
            <span className='navbar-item '>
              <Link Link to="/products">WIZUALIZACJA PRODUKTÓW</Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
