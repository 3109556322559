import { useState, useRef, useEffect, useMemo } from "react";
import * as THREE from "three";

import { Canvas, useLoader } from "@react-three/fiber";
import { OrbitControls, Html, useGLTF, Stage } from "@react-three/drei";
import { generateUUID } from "three/src/math/MathUtils.js";

function ModelItem({
  texturePath,
  position,
  rotation,
  scale,
  onAddLeft,
  onAddRight,
  onAddTop,
  canAddLeft,
  canAddRight,
  canAddTop,
  model,
  show,
}) {
  const modelRef = useRef();

  const texture = useLoader(THREE.TextureLoader, texturePath);

  const clonedModel = model.scene.clone();

  clonedModel.traverse((child) => {
    if (child.isMesh) {
      child.castShadow = true;
      child.receiveShadow = true;
      child.material = new THREE.MeshStandardMaterial({
        map: texture,
      });
    }
  });

  const ascpectRatio = model.size.x / model.size.y;

  const leftButtonPosition = [-model.size.x / 2 - ascpectRatio, 0, 0];
  const rightButtonPosition = [model.size.x / 2 + ascpectRatio, 0, 0];

  const topButtonPosition = [0, model.size.y / 2 + ascpectRatio, 0];
  const visible = show ? false : true;
  return (
    <group position={position} ref={modelRef}>
      <primitive object={clonedModel} rotation={rotation} scale={scale} />
      {visible && canAddLeft && (
        <Html position={leftButtonPosition}>
          <button onClick={onAddLeft} className="circleBtn"></button>
        </Html>
      )}
      {visible && canAddRight && (
        <Html position={rightButtonPosition}>
          <button onClick={onAddRight} className="circleBtn"></button>
        </Html>
      )}
      {visible && canAddTop && (
        <Html position={topButtonPosition}>
          <button onClick={onAddTop} className="circleBtn"></button>
        </Html>
      )}
    </group>
  );
}

function ConfiguratorStage({
  items,
  maxHorizontalLevels,
  maxVerticalLevels,
  addItemLeft,
  addItemRight,
  addItemTop,
  isItemAtPosition,
  isItemBelow,
  rotateEvenLayers,
  model,
  texturePath,
  show,
}) {
  const stageKey = items.map((item) => item.id).join("-");

  return (
    <Stage
      key={stageKey}
      intensity={0.1}
      preset="rembrandt"
      shadows={{
        type: "accumulative",
        bias: -0.001,
        intensity: Math.PI * 2,
      }}
      adjustCamera={false}
    >
      {items.map((item) => {
        const layerIndex = Math.floor(item.position[1] / model.size.y);
        const rotation =
          rotateEvenLayers && layerIndex % 2 === 1
            ? [Math.PI, 0, 0]
            : [0, 0, 0];

        const scale =
          rotateEvenLayers && layerIndex % 2 === 1 ? [-1, 1, 1] : [1, 1, 1];

        const canAddLeft =
          !isItemAtPosition([
            item.position[0] - model.size.x,
            item.position[1],
            item.position[2],
          ]) &&
          item.position[0] >
            -Math.floor(maxHorizontalLevels / 2) * model.size.x &&
          isItemBelow([
            item.position[0] - model.size.x,
            item.position[1],
            item.position[2],
          ]);

        const canAddRight =
          !isItemAtPosition([
            item.position[0] + model.size.x,
            item.position[1],
            item.position[2],
          ]) &&
          item.position[0] <
            Math.floor((maxHorizontalLevels - 1) / 2) * model.size.x &&
          isItemBelow([
            item.position[0] + model.size.x,
            item.position[1],
            item.position[2],
          ]);

        const canAddTop =
          Math.abs(item.position[1] / model.size.y) < maxVerticalLevels - 1 &&
          !isItemAtPosition([
            item.position[0],
            item.position[1] + model.size.y,
            item.position[2],
          ]) &&
          isItemBelow([
            item.position[0],
            item.position[1] + model.size.y,
            item.position[2],
          ]);

        return (
          <ModelItem
            key={item.id}
            position={item.position}
            rotation={rotation}
            scale={scale}
            onAddLeft={() => addItemLeft(item.position)}
            onAddRight={() => addItemRight(item.position)}
            onAddTop={() => addItemTop(item.position)}
            canAddLeft={canAddLeft}
            canAddRight={canAddRight}
            canAddTop={canAddTop}
            model={model}
            show={show}
            texturePath={texturePath}
          />
        );
      })}
    </Stage>
  );
}

export default function Configurator({
  modelUrl,
  maxHorizontalLevels,
  maxVerticalLevels,
  texturePath,
  layoutItems,
  sendLayout,
  show,
  rotateEvenLayers = false,
}) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (layoutItems) {
      setItems(layoutItems);
    }
  }, [layoutItems]);

  const model = useGLTF(modelUrl);

  const [modelSize, setModelSize] = useState(new THREE.Vector3());
  useEffect(() => {
    const box = new THREE.Box3().setFromObject(model.scene);
    const size = new THREE.Vector3();
    box.getSize(size);
    setModelSize(size);
  }, [model]);

  const groundPositions = useMemo(() => {
    const positions = [];
    const totalLevels = maxHorizontalLevels;
    const halfLevels = Math.floor(totalLevels / 2);

    for (let i = -halfLevels; i <= halfLevels; i++) {
      const x = i * modelSize.x;
      positions.push([x, 0, 0]);
    }

    return positions.slice(0, totalLevels);
  }, [maxHorizontalLevels, modelSize]);

  const addItem = (position) => {
    setItems((prev) => [...prev, { id: generateUUID(), position }]);
    sendLayout(position);
  };

  const isItemAtPosition = (position) => {
    return items.some((item) =>
      item.position.every((value, index) => value === position[index])
    );
  };

  const addItemLeft = (position) => {
    const newPos = [position[0] - modelSize.x, position[1], position[2]];
    if (!isItemAtPosition(newPos) && newPos[0] >= groundPositions[0][0]) {
      addItem(newPos);
    }
  };

  const addItemRight = (position) => {
    const newPos = [position[0] + modelSize.x, position[1], position[2]];
    if (
      !isItemAtPosition(newPos) &&
      newPos[0] <= groundPositions[groundPositions.length - 1][0]
    ) {
      addItem(newPos);
    }
  };

  const isItemBelow = (position) => {
    const belowPosition = [position[0], position[1] - modelSize.y, position[2]];
    if (position[1] === 0) return true;
    return isItemAtPosition(belowPosition);
  };

  const addItemTop = (position) => {
    const newPos = [position[0], position[1] + modelSize.y, position[2]];
    if (!isItemAtPosition(newPos) && isItemBelow(newPos)) {
      addItem(newPos);
    }
  };

  const [cameraPosition, setCameraPosition] = useState([0, 0, 150]);

  const calculateCameraPosition = () => {
    if (modelSize.x === 0) {
      return [0, 0, 150];
    }
    let marginFactor;
    let horizontalFactor;
    if (window.innerWidth > 800) {
      marginFactor = (maxHorizontalLevels * modelSize.x * 0.7) / 2;
      horizontalFactor = maxHorizontalLevels * modelSize.x * 0.7;
    } else {
      marginFactor = (maxHorizontalLevels * modelSize.x * 2) / 2;
      horizontalFactor = maxHorizontalLevels * modelSize.x * 2;
    }

    return [0, 0, marginFactor + horizontalFactor];
  };

  useEffect(() => {
    const updateCameraPosition = () => {
      setCameraPosition(calculateCameraPosition());
    };

    updateCameraPosition();

    window.addEventListener("resize", updateCameraPosition);

    return () => {
      window.removeEventListener("resize", updateCameraPosition);
    };
  }, [maxHorizontalLevels, modelSize, model]);

  return (
    <Canvas
      shadows
      camera={{ fov: 50, position: cameraPosition }}
      gl={{ physicallyCorrectLights: true }}
    >
      <ConfiguratorStage
        items={items}
        maxHorizontalLevels={maxHorizontalLevels}
        maxVerticalLevels={maxVerticalLevels}
        addItemLeft={addItemLeft}
        addItemRight={addItemRight}
        addItemTop={addItemTop}
        isItemAtPosition={isItemAtPosition}
        isItemBelow={isItemBelow}
        model={{ scene: model.scene, size: modelSize }}
        texturePath={texturePath}
        show={show}
        rotateEvenLayers={rotateEvenLayers}
      />

      {items.length === 0 &&
        groundPositions.map((pos, idx) => (
          <Html key={idx} position={pos}>
            <button onClick={() => addItem(pos)} className="circleBtn"></button>
          </Html>
        ))}

      <OrbitControls
        minPolarAngle={0}
        maxPolarAngle={Math.PI / 1.9}
        makeDefault
      />
    </Canvas>
  );
}
