import React, { useEffect, useState } from 'react';
import { Routes, Route, Link, Navigate } from 'react-router-dom'; // Importujemy Navigate
import ProductCatalog from './pages/products/products-catalog';
import AddProduct from './pages/products/add-products';
import Navbar from './shared/navbar/navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/shared/styles/styles.css'
import { get } from './shared/apiCaller/base-api-caller';
import {default as ConfiguratorPage} from './pages/configurator/ConfiguratorPage';

const App = () => {

  const [isAuthorised, setAuthorised] = useState(false);
  useEffect(() => {
    // Pobieramy parametry z URL
    const params = new URLSearchParams(window.location.search);
    const newToken = params.get('token'); // Pobieramy nowy token z URL

    if (newToken) {
      const existingToken = localStorage.getItem('authToken'); // Pobieramy obecny token z localStorage

      if (newToken !== existingToken) {
        localStorage.setItem('authToken', newToken);

        alert('Nowy token został zapisany. Jest ważny przez 1 godzinę. Po tym czasie musisz się ponownie autoryzować.');

        setTimeout(() => {
          alert('Token stracił ważność. Wróć na stronę wp-admin i przejdź na tą stronę za pomocą przycisku Model3D w menu admina.');
        }, 60 * 60 * 1000);
      }
    }

    checkToken();
  }, []);

  const checkToken = async () => {
    const res = await get(`/Authorise/checkToken`);
    if (res?.isSuccess) {
      setAuthorised(true)
    } else {
      setAuthorised(false)
    }
  };

  // if (!isAuthorised) return (
  //   <div style={{ margin: '0 20px', height: '100vh' }} className="d-flex justify-content-center align-items-center">
  //     <div className="text-center">
  //       <h4>Brak autoryzacji</h4>
  //       <p>Nie masz odpowiednich uprawnień, aby uzyskać dostęp do tej strony.</p>
  //     </div>
  //   </div>
  // );
  const ProtectedRoute = ({ children }) => {
    if (!isAuthorised) {
      return (
        <div
          style={{ margin: "0 20px", height: "100vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          <div className="text-center">
            <h4>Brak autoryzacji</h4>
            <p>
              Nie masz odpowiednich uprawnień, aby uzyskać dostęp do tej strony.
            </p>
          </div>
        </div>
      );
    }
    return children;
  };

  return (
    <div style={{ margin: "0 20px", overflowY: "auto", overflowX: "hidden", height: "100%" }}>
      <Routes>
        <Route path="/configurator/:mainId" element={<ConfiguratorPage />} />
        <Route
          path="/*"
          element={
            <ProtectedRoute isAuthorised={isAuthorised}>
              <div style={{ margin: "0 20px" }}>
                <Navbar />
                <Routes>
                  <Route
                    path="/"
                    element={<Navigate to="/products" replace />}
                  />
                  <Route path="/products" element={<ProductCatalog />} />
                  <Route path="/products/edit" element={<AddProduct />} />
                </Routes>
              </div>
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );

};

export default App;
